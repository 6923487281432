<template>
    <div class="page-box">
        <div class="container">
            <div class="tips-text">
                <p>支持 微信 扫码支付、付款码支付、公众号支付、小程序支付、H5支付、APP支付</p>
                <p>可白定义收款名称、配置支付目录、发起退款、同一身份证可申请多个，满足多项目资金分管。</p>
                <p>支持 支付宝 扫码支付、WAP支付、JS支付、H5支付、APP支付</p>
                <p>微信、支付宝官方备案，官方开户!资金实时到帐，官方直连结算。无第三方中转!</p>
                <p>无中间监听、无APP安装、正规官方直连支付接口</p>
                <p>10分钟极速开户，官方审核。</p>
            </div>
            <div class="order-info">
                <div class="tips">订单信息</div>
                <div class="meta">
                    <div class="cell">订单编号：<span class="order-no">{{order.mchOrderNo}}</span></div>
                    <div class="cell">商品名称：<span class="goods-name">{{order.subject}}</span></div>
                    <div class="cell">支付金额：<span class="amount">{{order.amount/100}}</span></div>
                </div>
            </div>
            <div class="platform">
                <div class="platform-swapper">
                    <div class="tips">微信支付</div>
                    <div class="platform-list">
                        <div :class="order.wayCode=='WX_NATIVE'?'platform-item active':'platform-item'" @click="order.wayCode='WX_NATIVE';order.mchOrderNo='P'+new Date().getTime()">
                            <div class="platform-item-inner">
                                <img src="@/assets/nativePay.png" width="40px" height="40px"/>
                                <span>扫码支付</span>
                            </div>
                        </div>
                        <div :class="order.wayCode=='WX_JSAPI'?'platform-item active':'platform-item'" @click="order.wayCode='WX_JSAPI';order.mchOrderNo='P'+new Date().getTime()">
                            <div class="platform-item-inner">
                                <img src="@/assets/jsApiPay.png" width="40px" height="40px"/>
                                <span>JSAPI支付</span>
                            </div>
                        </div>
                        <div :class="order.wayCode=='CASHIER'?'platform-item active':'platform-item'" @click="order.wayCode='CASHIER';order.mchOrderNo='P'+new Date().getTime()">
                            <div class="platform-item-inner">
                                <img src="@/assets/cashierPay.png" width="40px" height="40px"/>
                                <span>收银台支付</span>
                            </div>
                        </div>
                        <div :class="order.wayCode=='WX_H5'?'platform-item active':'platform-item'" @click="order.wayCode='WX_H5';order.mchOrderNo='P'+new Date().getTime()">
                            <div class="platform-item-inner">
                                <img src="@/assets/h5Pay.png" width="40px" height="40px"/>
                                <span>H5支付</span>
                            </div>
                        </div>
                        <div :class="order.wayCode=='WX_LITE'?'platform-item active':'platform-item'" @click="order.wayCode='WX_LITE';order.mchOrderNo='P'+new Date().getTime()">
                            <div class="platform-item-inner">
                                <img src="@/assets/minAppPay.png" width="40px" height="40px"/>
                                <span>微信小程序</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="platform-swapper">
                    <div class="tips">支付宝</div>
                    <div class="platform-list">
                        <div :class="order.wayCode=='ALI_QR'?'platform-item active':'platform-item'" @click="order.wayCode='ALI_QR';order.mchOrderNo='P'+new Date().getTime()">
                            <div class="platform-item-inner">
                                <img src="@/assets/nativePay (1).png" width="40px" height="40px"/>
                                <span>扫码支付</span>
                            </div>
                        </div>
                        <div :class="order.wayCode=='ALI_PC'?'platform-item active':'platform-item'" @click="order.wayCode='ALI_PC';order.mchOrderNo='P'+new Date().getTime()">
                            <div class="platform-item-inner">
                                <img src="@/assets/h5Pay (1).png" width="40px" height="40px"/>
                                <span>H5支付</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="platform-swapper">
                    <div class="tips">聚合支付</div>
                    <div class="platform-list">
                        <div :class="order.wayCode=='QR_CASHIER'?'platform-item active':'platform-item'" @click="order.wayCode='QR_CASHIER';order.mchOrderNo='P'+new Date().getTime()">
                            <div class="platform-item-inner">
                                <img src="@/assets/mergePay.png" width="40px" height="40px"/>
                                <span>聚合一码付</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="pay-swapper">
                <div class="pay-swapper-inner">
                    <div class="amount">￥ {{order.amount/100}}</div>
                    <div class="btn" @click="handlePay">立即支付</div>
                </div>
            </div>
            <div class="tips-text footer">
                <p class="tips">付款须知：</p>
                <p>本次支付只提供支付接口功能演示，无实际产品或服务。付款后您可在本页自行发起退款，如有疑问请联系在线客服。</p>
            </div>
        </div>
        <van-dialog v-model="showDialog" :before-close="handleClose" className="dialog-swapper" :title="title" :showConfirmButton="false"  show-cancel-button>
            <img v-if="miniappUrl" :src="miniappUrl" width="200" height="200"/>
            <vue-qr v-else :text="codeImgUrl" :size="300" :margin="10"></vue-qr>
            <p style="width:100%;text-align: center;font-size:16px;">支付金额：￥ {{order.amount/100}}</p>
        </van-dialog>
    </div>
</template>
<script>
//import {refundQuery, refundOrder, unifiedOrder, unifiedOrderQuery} from '@/api/api'
import {unifiedOrder,unifiedOrderQuery} from '@/api/api'
import VueQr from 'vue-qr'
export default {
    name:"PayDemo",
    components:{VueQr},
    data: function (){
        return {
            showDialog:false,
            order:{
                mchOrderNo:'P'+new Date().getTime(),
                subject:'测试商品',
                body:'商品描述信息',
                amount:1,
                wayCode:'WX_NATIVE'
            },
            codeImgUrl:'',
            title:'支付二维码',
            timer:undefined,
            miniappUrl:''
        }
    },
    created(){
    //   let code  =   this.getParamUrl('code');
    },
    beforeDestroy(){
        if(this.timer){
            window.clearInterval(this.timer);
        }
    },
    methods:{
        //订单支付
        handlePay(){
            this.miniappUrl=undefined;
            if(this.order.wayCode == 'WX_JSAPI' && this.isWeixinOrAlipay()!='WeiXIN'){
                 alert("请在微信端访问体验");
                 return;
            }
            if(this.order.wayCode == 'WX_JSAPI'&&!this.isWeChat()){
                alert('微信外不支持JSAPI支付，请在微信中打开页面');
                return;
            }
            if((this.order.wayCode == 'WX_H5'|| this.order.wayCode == 'ALI_PC') && !this.isPhone()){
                alert("请使用手机浏览器访问体验")
                return;
            }
            let temp = {... this.order}
            if(this.order.wayCode == 'CASHIER'){
               temp.wayCode = 'WX_NATIVE';
            }
            if(this.order.wayCode == 'WX_JSAPI'||this.order.wayCode == 'ALI_PC'){
                temp.wayCode = 'QR_CASHIER';
            }
            if(this.order.wayCode == 'WX_H5'){
               alert("暂不支持，请等待...")
                return;
            }
            if(this.order.wayCode == 'WX_LITE'){
                this.title = '微信小程序';
                this.showDialog=true;
                this.miniappUrl = 'https://pay.uipay.cn/api/demo/wxapp/getwxacodeunlimit?scene='+this.order.mchOrderNo+"&page=pages/miniappPay";
                return;
            }
            unifiedOrder(temp).then(res=>{
                if(res.payDataType == 'payurl'){
                    this.codeImgUrl = res.payData;
                    if(this.order.wayCode == 'CASHIER'){
                        this.$router.push({ path: "/demoCashier?mchOrderNo=" + res.mchOrderNo + "&payOrderId="+res.payOrderId+"&codeImgUrl="+decodeURI(this.codeImgUrl)}).catch(() => { });
                        return;
                    }
                    if(this.isWeixinOrAlipay()=='WeiXIN'){
                        window.open(this.codeImgUrl);
                        return;
                    }
                    if(this.isWeixinOrAlipay()=='Alipay'){
                        window.open(this.codeImgUrl);
                        return;
                    }
                    if(this.order.wayCode == 'WX_NATIVE'){
                        this.title = '微信扫码支付';
                        this.showDialog=true;
                        this.orderTask(res.mchOrderNo,res.payOrderId);
                        return;
                    }
                    if(this.order.wayCode == 'ALI_QR'){
                        this.title = '支付宝扫码支付';
                        this.showDialog=true;
                        this.orderTask(res.mchOrderNo,res.payOrderId);
                        return;
                    }
                    if(this.order.wayCode == 'QR_CASHIER'){
                        this.title = '支付宝/微信扫码支付';
                        this.showDialog=true;
                        this.orderTask(res.mchOrderNo,res.payOrderId);
                        return;
                    }
                    if(this.order.wayCode == 'WX_JSAPI'){
                        window.open(this.codeImgUrl);
                        return;
                    }
                    if(this.order.wayCode == 'ALI_PC'){
                        window.open(res.aliH5Url);
                        return;
                    }
                }
            }).catch((err)=>{
                alert(err.msg);
                this.order.mchOrderNo='P'+new Date().getTime();
            })
        },
        orderTask(mchOrderNo,payOrderId){
            if(this.timer){
                window.clearInterval(this.timer);
                this.timer=undefined;
            }
            this.timer = window.setInterval(()=>{
                unifiedOrderQuery({mchOrderNo,payOrderId}).then(res=>{
                    if(res.state==2){
                       this.showDialog=false;
                       window.clearInterval(this.timer);
                       this.$router.push({ path: "/order?mchOrderNo=" + res.mchOrderNo + "&payOrderId="+res.payOrderId}).catch(() => { });
                       return;
                    }
                    if(res.state==3){
                       this.showDialog=false;
                       alert("支付失败")
                       window.clearInterval(this.timer);
                    }
                    if(res.state==4){
                        this.showDialog=false;
                        alert('已撤销');
                        window.clearInterval(this.timer);
                    }
                    if(res.state==5){
                       this.showDialog=false;
                       alert('已退款');
                        window.clearInterval(this.timer);
                    }
                    if(res.state==6){
                       this.showDialog=false;
                       alert('订单关闭');
                       window.clearInterval(this.timer);
                    }
                }).catch((err)=>{
                    alert(err);
                })
            },1000);
        },
        handleClose(action, done){
            if(this.timer){
                this.showDialog=false;
                window.clearInterval(this.timer);
                this.timer=undefined;
            }
            done()
        },
        isPhone(){
            const regexMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
            return regexMobile.test(navigator.userAgent);
        },
        isWeChat() {
            var ua = navigator.userAgent.toLowerCase();
            if (ua.match(/MicroMessenger/i) == 'micromessenger') {
                return true;
            } else {
                return false;
            }
        },
        isZhifu() {
            var ua = navigator.userAgent.toLowerCase();
            if (ua.match(/MicroMessenger/i) == 'micromessenger') {
                return true;
            } else {
                return false;
            }
        },
        getParamUrl(name) {
            let url = location.href;
            if (url.indexOf('?') == -1) return '';

            try {
                var re = new RegExp("" + name + "=([^&?]*)", "ig");
                return ((url.match(re)) ? (decodeURIComponent(url.match(re)[0].substr(name.length + 1))) : '');
            } catch (_e) {
                return '';
            }
        },
        isWeixinOrAlipay(){
            var ua = window.navigator.userAgent.toLowerCase();
            //判断是不是微信
            if ( ua.match(/MicroMessenger/i) == 'micromessenger' ) {  
                return "WeiXIN";  
            }    
            //判断是不是支付宝
            if (ua.match(/AlipayClient/i) == 'alipayclient') {
                return "Alipay";  
            }
            //哪个都不是
            return "false";
        }
    }
}
</script>
<style scoped>
.page-box *{
    text-align: left;
}
.dialog-swapper{
    text-align: center;
    width: 150px;
    height: 172px;
}
.dialog-swapper /deep/  .van-dialog__content img{
    width: 100px;
    height: 100px;
}
.dialog-swapper /deep/ .van-dialog__header{
    margin:5px auto;
}
.dialog-swapper /deep/ .van-button{
   padding:10px 0px;
   font-size: 8px;
   font-weight: 550;
}
@media screen and (min-width:1336px){
    .page-box *{
        font-size: 6px;
    }
    .dialog-swapper{
        height: 180px;
    }
}
@media screen and (min-width:1024px) and (max-width:1336px){
    .dialog-swapper{
        width: 350px;
        height: 410px;
    }
    .dialog-swapper /deep/ .van-dialog__header{
        font-size: 25px;
        margin:10px auto;
    }
    .dialog-swapper /deep/  .van-dialog__content img{
        width: 250px;
        height: 250px;
    }
    .dialog-swapper /deep/ .van-button{
        font-size: 18px;
        font-weight: 550;
    }
}
@media screen and (min-width:667px) and (max-width:1024px){
    .page-box *{
        font-size: 14px;
    }
    .dialog-swapper{
        width: 250px;
        height: 310px;
    }
    .dialog-swapper /deep/  .van-dialog__content img{
        width: 150px;
        height: 150px;
    }
    .dialog-swapper /deep/ .van-button{
        font-size: 18px;
        font-weight: 550;
    }
}
@media screen and (max-width:667px){
    .page-box *{
        font-size: 30px;
    }
    .dialog-swapper{
        width: 500px;
        height: 500px;
    }
    .dialog-swapper /deep/  .van-dialog__content img{
        width: 200px;
        height: 200px;
    }
    .dialog-swapper /deep/ .van-dialog__header{
        margin:5px auto;
    }
    .dialog-swapper /deep/ .van-button{
        padding:10px 0px;
        font-size: 18px;
    }
}
</style>
<style>
body,.page-box{
    width: 100%;
    padding: 0;
    margin: 0;
    background-color: #f9f9f9;
    min-height: 100vh;
}
.container{
    width: 80%;
    margin:0 auto;
}
.container .tips-text{
    width: 100%;
    background-color: #fff2e0;
    border: 1px solid #fff2e0;
    border-radius: 5px;
    margin: 5px 0px;
}
.container .tips-text p{
    padding-left: 10px;
    margin: 2px 0px;
    color: #44b549;
}
.order-info{
    margin: 5px 0px;
    background-color: #fff;
    padding: 10px;
    border-radius: 5px;
}
.order-info .meta{
    display: flex;
    margin-top: 5px;
    flex-wrap: wrap;
}
.order-info .meta .cell{
    min-width: 180px;
    padding:10px;
}
.platform{
    background-color: #fff;
    padding: 5px 10px;
    border-radius: 5px;
}
.platform .platform-list{
    display: flex;
    margin: 5px 0px;
    flex-wrap: wrap;
}
.platform .platform-list .platform-item{
    width: 100px;
    height: 30px;
    line-height: 30px;
    border: 1px solid #ccc;
    margin:5px 10px;
    cursor: pointer;
}
.platform .platform-list .platform-item:hover,.platform .platform-list .platform-item.active{
    border: 1px solid #2077ff;
    color:#2077ff;
}
.platform .platform-list .platform-item img{
    vertical-align: middle;
    margin-left: 10px;
    margin-right: 10px;
}
.pay-swapper{
    background-color: #fff;
    height: 30px;
    line-height: 30px;
    width: 100%;
    margin: 5px 0px;
    padding: 10px 0px;
    border-radius: 5px;
}
.pay-swapper .pay-swapper-inner{
    display: flex;
    justify-content: right;
}
.pay-swapper .pay-swapper-inner .amount{
    font-size: 10px;
    color: red;
    margin-right: 10px;
    font-weight: 550;
}
.pay-swapper .pay-swapper-inner .btn{
    background-color: #2077ff;
    width: 100px;
    font-size: 10px;
    font-weight: 550;
    text-align: center;
    color: #fff;
    border-radius: 20px;
    cursor: pointer;
    margin-right: 20px;
}
.container .footer{
    background-color: #fff;
    border: 1px solid #fff;
}
.container .footer p{
    color: #000;
}
/** 适配样式处理 */
@media screen and (min-width:1024px){
    .container .tips-text{
        margin: 5px 0px;
    }
    
    .container .tips-text p{
        padding-left: 10px;
        margin: 2px 0px;
    }
    .order-info{
        margin: 5px 0px;
        padding: 10px;
    }
    .order-info .meta{
        margin-top: 5px;
    }
    .order-info .meta .cell{
        min-width: 100px;
    }
    .platform{
        padding: 5px 10px;
        border-radius: 5px;
    }
    .platform .platform-list{
        margin: 5px 0px;
    }
    .platform .platform-list .platform-item{
        height: 30px;
        line-height: 30px;
        margin:5px 5px;
        width: 80px;
       border: 0.5px solid #ccc;
    }
    .platform .platform-list .platform-item img{
       width: 20px;
       height: 20px;
       margin-left: 5px;
       margin-right: 5px;
    }
    .pay-swapper{
        height: 30px;
        line-height: 30px;
        margin: 5px 0px;
        padding: 5px 0px;
    }
    .pay-swapper .pay-swapper-inner .amount{
        font-size: 10px;
    }
    .pay-swapper .pay-swapper-inner .btn{
        width: 90px;
        font-size: 9px;
        margin-right: 20px;
    }
    .order-info .tips,.platform-swapper .tips,.tips-text .tips{
        font-size: 7px;
    }
}
@media screen and (min-width:668px) and (max-width:1024px){
    .container .tips-text{
        margin: 5px 0px;
    }
    .container .tips-text p{
        margin: 2px 0px;
    }
    .order-info{
        margin: 5px 0px;
        padding: 10px;
    }
    .order-info .meta{
        margin-top: 5px;
    }
    .order-info .meta .cell{
        min-width: 180px;
    }
    .platform{
        padding: 5px 10px;
    }
    .platform .platform-list{
        margin: 5px 0px;
    }
    .platform .platform-list .platform-item{
        width: 150px;
        height: 60px;
        line-height: 60px;
        margin:5px 10px;
    }
    .platform .platform-list .platform-item img{
        margin-left: 10px;
        margin-right: 10px;
        width: 45px;
        height: 45px;
    }
    .pay-swapper{
        height: 50px;
        line-height: 50px;
        margin: 5px 0px;
        padding: 10px 0px;
    }
    .pay-swapper .pay-swapper-inner .amount{
        font-size: 18px;
        margin-right: 10px;
    }
    .pay-swapper .pay-swapper-inner .btn{
        width: 150px;
        font-size: 18px;
        margin-right: 20px;
    }
    .order-info .tips,.platform-swapper .tips,.tips-text .tips{
        font-size: 18px;
    }
}
@media screen and (max-width:667px){
    .container{
        width: 96%;
        margin: 0 auto;
    }
    .container .tips-text{
        margin: 20px 0px;
    }
    .container .tips-text p{
        padding-left: 10px;
        margin: 10px 0px;
    }
    .order-info{
        margin: 10px 0px;
        padding: 10px;
    }
    .order-info .meta{
        margin-top: 20px;
    }
    .order-info .meta .cell{
        min-width: 200px !important;
    }
    .platform{
        padding: 20px;
    }
    .platform .platform-list{
        margin: 20px 0px;
    }
    .platform .platform-list .platform-item{
        width: 300px;
        height: 100px;
        line-height: 100px;
        margin:10px;
    }
    .platform .platform-list .platform-item img{
        margin-left: 10px;
        margin-right: 10px;
        width:75px;
        height: 75px;
    }
    .pay-swapper{
        height: 100px;
        line-height: 100px;
        width: 100%;
        margin: 10px 0px;
        padding: 10px 0px;
    }
    .pay-swapper .pay-swapper-inner .amount{
        font-size: 35px;
        margin-right: 10px;
    }
    .pay-swapper .pay-swapper-inner .btn{
        width: 250px;
        font-size: 35px;
        margin-right: 20px;
    }
    .order-info .tips,.platform-swapper .tips,.tips-text .tips{
        font-size: 35px;
    }
}
</style>